export default function (html) {
    var s = "";
    if (html == undefined) return "";
    s = html.replace(/&amp;/g, "&");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&gt;/g, ">");
    s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/&#39;/g, "'");
    s = s.replace(/&quot;/g, '"');
    s = s.replace(/<br\/>/g, "\n");
    s = s.replace(/&ldquo;/g, '"');
    s = s.replace(/&rdquo;/g, '"');
    s = s.replace(/&rarr;/g, "→");
    s = s.replace(/<\/?[^>]*>/g, ""); //过滤标签
    return s;
}