import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fe9c6ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "paginationBox" }

import { ref, watch } from "vue";

export default {
  props: {
  total: Number,
  changePage: Number,
},
  emits: ["getCurrentPage"],
  setup(__props, { expose, emit: emits }) {

const props = __props

let current_page = ref(1);


expose({
  setCurPage(page) {
    current_page.value = page;
  },
});

// 点击首页分类，默认页回到1
/* watch(
  () => props.changePage,
  (val) => {
    current_page.value = 1;
  }
); */

const changePageHandler = (params) =>
  emits("getCurrentPage", params); /* 当前翻页 */

return (_ctx, _cache) => {
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      layout: "prev, pager, next,jumper",
      total: __props.total,
      "current-page": _unref(current_page),
      "onUpdate:current-page": _cache[0] || (_cache[0] = $event => (_isRef(current_page) ? (current_page).value = $event : current_page = $event)),
      onCurrentChange: changePageHandler
    }, null, 8, ["total", "current-page"])
  ]))
}
}

}